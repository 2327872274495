/**
 * @generated SignedSource<<7087cab18c8225cbd314de01eee57c14>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Ordering = "ASC" | "DESC" | "%future added value";
export type MATStudentFilter = {
  AND?: MATStudentFilter | null;
  NOT?: MATStudentFilter | null;
  OR?: MATStudentFilter | null;
  branch_Exact?: string | null;
  branch_In?: ReadonlyArray<string> | null;
  classroom_In?: ReadonlyArray<string> | null;
  classroom_Not_In?: ReadonlyArray<string> | null;
  created_Gte?: any | null;
  created_Lte?: any | null;
  distinct?: boolean | null;
  email_Exact?: string | null;
  email_In?: ReadonlyArray<string> | null;
  homework_In?: ReadonlyArray<string> | null;
  id?: string | null;
  id_Exact?: string | null;
  id_In?: ReadonlyArray<string> | null;
  id_Not_In?: ReadonlyArray<string> | null;
  isVerified_Exact?: boolean | null;
  modified_Gte?: any | null;
  modified_Lte?: any | null;
  organization_Exact?: string | null;
  search?: string | null;
  username_Exact?: string | null;
};
export type MATStudentOrder = {
  loginId?: Ordering | null;
  realname?: Ordering | null;
  studentType?: Ordering | null;
};
export type MATStudentWindowPaginatorRefreshQuery$variables = {
  filters?: MATStudentFilter | null;
  order?: MATStudentOrder | null;
  page?: number | null;
  pageSize?: number | null;
};
export type MATStudentWindowPaginatorRefreshQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"MATStudentWindowPaginator_query">;
};
export type MATStudentWindowPaginatorRefreshQuery = {
  response: MATStudentWindowPaginatorRefreshQuery$data;
  variables: MATStudentWindowPaginatorRefreshQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filters"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "order"
  },
  {
    "defaultValue": 1,
    "kind": "LocalArgument",
    "name": "page"
  },
  {
    "defaultValue": 20,
    "kind": "LocalArgument",
    "name": "pageSize"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "filters",
    "variableName": "filters"
  },
  {
    "kind": "Variable",
    "name": "order",
    "variableName": "order"
  },
  {
    "kind": "Variable",
    "name": "page",
    "variableName": "page"
  },
  {
    "kind": "Variable",
    "name": "pageSize",
    "variableName": "pageSize"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "page",
  "storageKey": null
},
v3 = [
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MATStudentWindowPaginatorRefreshQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "MATStudentWindowPaginator_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MATStudentWindowPaginatorRefreshQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "MATStudentConnection",
        "kind": "LinkedField",
        "name": "organizationStudents",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Pages",
            "kind": "LinkedField",
            "name": "pages",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Page",
                "kind": "LinkedField",
                "name": "first",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Page",
                "kind": "LinkedField",
                "name": "around",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isCurrent",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Page",
                "kind": "LinkedField",
                "name": "last",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Page",
                "kind": "LinkedField",
                "name": "next",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Page",
                "kind": "LinkedField",
                "name": "previous",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "MATStudentEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "MATStudent",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "loginId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "studentType",
                    "storageKey": null
                  },
                  {
                    "alias": "userId",
                    "args": null,
                    "kind": "ScalarField",
                    "name": "sequence",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "friendTag",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6bd1683c1768301520b895c130a4a9f4",
    "id": null,
    "metadata": {},
    "name": "MATStudentWindowPaginatorRefreshQuery",
    "operationKind": "query",
    "text": "query MATStudentWindowPaginatorRefreshQuery(\n  $filters: MATStudentFilter\n  $order: MATStudentOrder\n  $page: Int = 1\n  $pageSize: Int = 20\n) {\n  ...MATStudentWindowPaginator_query_36liK3\n}\n\nfragment MATStudentConnectionDataTable_matStudentConnection on MATStudentConnection {\n  edges {\n    node {\n      id\n      loginId\n      studentType\n      userId: sequence\n      friendTag\n      name\n    }\n  }\n}\n\nfragment MATStudentWindowPaginator_query_36liK3 on Query {\n  organizationStudents(filters: $filters, order: $order, page: $page, pageSize: $pageSize) {\n    totalCount\n    pages {\n      ...Pagination_pages\n    }\n    edges {\n      node {\n        id\n      }\n    }\n    ...MATStudentConnectionDataTable_matStudentConnection\n  }\n}\n\nfragment Pagination_pages on Pages {\n  first {\n    page\n  }\n  around {\n    page\n    isCurrent\n  }\n  last {\n    page\n  }\n  next {\n    page\n  }\n  previous {\n    page\n  }\n}\n"
  }
};
})();

(node as any).hash = "d39aab641b917a4e4eb6f85905d2a3ad";

export default node;
